import React from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

const MaterialTableDemo = ({colums, tabla,clase,exportExcel, className = '', keyCol=null, addConfig, ...rest}) => {

  const config = {
    key_column:keyCol,
    page_size: 10,
    length_menu: [10, 20, 50, 100,500,1000, 10000],
     button: {
       print: false,
       extra: false,
       excel: exportExcel ? true : false 
     },
    pagination: 'advance',
    language: {
      length_menu: 'Mostrar _MENU_ registros por página',
      filter: 'Buscar en registros ...',
      info: 'Mostrando _START_ a _END_ de _TOTAL_ registros',
      pagination: {
        first: 'Primero',
        previous: <FontAwesomeIcon icon={faArrowAltCircleLeft} /> ,
        next: <FontAwesomeIcon icon={faArrowAltCircleRight} />,
        last: 'Último'
      },
    },
    ...addConfig
  };

  return(
      <ReactDatatable
        config={config}
        records={tabla}
        columns={colums}
       
        show_filter={true}
        show_info={true}
        className={`datatable table-responsive-sm table-striped ${className}`}
        {...rest}
      />
  )
}

export default MaterialTableDemo