import React, { useState,useEffect,useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {listarProyecto} from "../../../actions/cursosActions";
import { asignarPlan } from "../../../actions/comercialActions";
import { Form, Col, Button, Spinner } from "react-bootstrap";



const EditPlanModal = ({ id_proyecto,id_contrato, planActual, onSave, modalidad }) => {

  const dispatch = useDispatch();
  const proyecto = useSelector(state => state.cursos.proyecto);
  const [planesSelect, setPlanesSelect] = useState([]);
  const [conceptosFinales, setConceptosFinales] = useState([]);
	const [valueSelecPlanes, setvalueSelectPlanes] = useState("");
	const [valueSelecPlan, setvalueSelectPlan] = useState("");
	const [plan, setPlan] = useState({});

  const obtenerPlanes = (modalidad) => {
    // Verificar que proyecto y proyecto.planes existan antes de intentar acceder a sus propiedades
    if (proyecto && proyecto.planes) {
      if (modalidad === "contado") {
        setPlanesSelect(proyecto.planes.planPagoUnico || []);
      } else if (modalidad === "mensualidades") {
        setPlanesSelect(proyecto.planes.planPagosMensualidades || []);
      } else if (modalidad === "cuotas") {
        setPlanesSelect(proyecto.planes.planPagoCuotas || []);
      }
    } else {
      setPlanesSelect([]); // En caso de que proyecto o planes no estén disponibles, se asegura de no romper el código.
    }
  };

  const resetStates = () => {
		setvalueSelectPlan("");
		setConceptosFinales([]);
		//setvalueSelectPlanes("");
		setPlan({});
	};

  const selectRef = useRef();



  useEffect(() => {
    const fetchData = async () => {
      await dispatch(listarProyecto(id_proyecto)); // Esperar hasta que se obtenga el proyecto
    };
  
    fetchData();
  }, [id_proyecto]);
  
  // Ejecutar obtenerPlanes solo cuando 'proyecto' cambia y ya está cargado
  useEffect(() => {
    if (proyecto && modalidad) {
      obtenerPlanes(modalidad);
    }
  }, [proyecto, modalidad]);
  


  const handleSave = (e) => {
    e.preventDefault();
    const datos={
      id_plan:plan.id_plan,
      id_contrato: id_contrato
    }
    onSave(datos);
    dispatch(asignarPlan(datos));
  };

  return (
    <div className="container p-4">
      <Form onSubmit={handleSave}>
        <Form.Row>
          {/*<Form.Group as={Col} md={6}>
            <Form.Label>
              Seleccionar Modalidad
            </Form.Label>
            <Form.Control
                as="select"
                ref={selectRef}
                value={valueSelecPlanes}
                onChange={(e) => {
                  resetStates();
                  setvalueSelectPlanes(e.target.value);
                  obtenerPlanes(parseInt(e.target.value));
                }}
              >
                <option value="" disabled>
                  --Seleccione--
                </option>
                <option value="2">INVERSION AL CONTADO</option>
                <option value="3">CUOTAS</option>
                <option value="1">MENSUALIDADES</option>
              </Form.Control>
           
           </Form.Group>*/}
           <Form.Group as={Col} md={6}>
						<Form.Label>Seleccionar el plan</Form.Label>
						<Form.Control
							as="select"
							value={valueSelecPlan}
							onChange={(e) => {
								setvalueSelectPlan(e.target.value);
								let planValue = planesSelect.find(
									(plan) =>
										plan.id_plan ===
										parseInt(e.target.value)
								);
								setPlan(planValue);
							}}
						>
							<option value="" disabled>
								--Seleccione--
							</option>
							{planesSelect.length > 0
								? planesSelect.map((plan) => (
										<option
											key={plan.id_plan}
											value={plan.id_plan}
										>
											{plan.nombre_plan} - {plan.tp_plan}
										</option>
								  ))
								: null}
						</Form.Control>
					</Form.Group>
          <Form.Group as={Col} md={6} className="text-right">
						<Button
							className="btn-spinner"
							variant="info"
							type="submit"
						>
							Asignar plan
						</Button>
					</Form.Group>
        </Form.Row>
      </Form>
    </div>
  );
};

export default EditPlanModal;
