export const PROFORMA_GUARDAR_EXITO = 'PROFORMA_GUARDAR_EXITO';
export const PROFORMA_GUARDAR_ERROR = 'PROFORMA_GUARDAR_ERROR';
export const PROFORMA_LISTAR_EXITO = 'PROFORMA_LISTAR_EXITO';
export const PROFORMA_LISTAR_ERROR = 'PROFORMA_LISTAR_ERROR';

export const GUARDAR_DESEMPENO_DOCENTE = 'GUARDAR_DESEMPENO_DOCENTE';
export const GUARDAR_DESEMPENO_DOCENTE_EXITO = 'GUARDAR_DESEMPENO_DOCENTE_EXITO';
export const GUARDAR_DESEMPENO_DOCENTE_ERROR = 'GUARDAR_DESEMPENO_DOCENTE_ERROR';


export const OBTENER_DESEMPENO_DOCENTE = 'OBTENER_DESEMPENO_DOCENTE';
export const OBTENER_DESEMPENO_DOCENTE_EXITO = 'OBTENER_DESEMPENO_DOCENTE_EXITO';
export const OBTENER_DESEMPENO_DOCENTE_ERROR = 'OBTENER_DESEMPENO_DOCENTE_ERROR';


export const OBTENER_DESEMPENO_PROYECTO_EXITO = 'OBTENER_DESEMPENO_PROYECTO_EXITO';
export const OBTENER_RANKING_DOCENTES_EXITO = 'OBTENER_RANKING_DOCENTES_EXITO';
export const OBTENER_LISTA_PROMEDIOS_DOCENTES_EXITO = 'OBTENER_LISTA_PROMEDIOS_DOCENTES_EXITO';
export const OBTENER_REPORTE_ERROR = 'OBTENER_REPORTE_ERROR';

export const FILTRAR_DESEMPEÑO_PROYECTO_EXITO = 'FILTRAR_DESEMPEÑO_PROYECTO_EXITO'
export const FILTRAR_DESEMPEÑO_ERROR = 'FILTRAR_DESEMPEÑO_ERROR'


export const OBTENER_DETALLES_SESION_EXITO = 'OBTENER_DETALLES_SESION_EXITO';
export const OBTENER_DETALLES_SESION_ERROR  = 'OBTENER_DETALLES_SESION_ERROR ';

export const AGREGAR_LINK_DRIVE_ERROR  = 'AGREGAR_LINK_DRIVE_ERROR ';
export const AGREGAR_LINK_DRIVE_EXITO  = 'AGREGAR_LINK_DRIVE_EXITO ';

export const MOSTRAR_CURSOS = "MOSTRAR_CURSOS";
export const MOSTRAR_CURSO = "MOSTRAR_CURSO";
export const ELIMINAR_MODULO = "ELIMINAR_MODULO";
export const MOSTRAR_COMUNICADO = "MOSTRAR_COMUNICADO";
export const ELIMINAR_TUTOR = "ELIMINAR_TUTOR";
export const OBTENER_COMENTARIOS_VIDEOS = "OBTENER_COMENTARIOS_VIDEOS";
export const LLENAR_SELECT_PROYECTOS = 'LLENAR_SELECT_PROYECTOS';
export const LLENAR_SELECT_TC = 'LLENAR_SELECT_TC';
export const LLENAR_SELECT_CURSOS = 'LLENAR_SELECT_CURSOS'
export const LLENAR_SELECT_CURSOS_POSGRADO = 'LLENAR_SELECT_CURSOS_POSGRADO'
export const LISTAR_SESIONES_CURSOS = 'LISTAR_SESIONES_CURSOS'
export const LISTAR_SESIONES_CURSO_IMPORT = 'LISTAR_SESIONES_CURSO_IMPORT'
export const LISTAR_ALUMNOS_CURSO = 'LISTAR_ALUMNOS_SESIONES'
export const INSERTAR_PROYECTO = 'INSERTAR_PROYECTO'
export const LISTAR_PROYECTOS = 'LISTAR_PROYECTOS'
export const EDITAAR_PROYECTO = 'EDITAR_PROYECTO'
export const EDITAR_MODULO = 'EDITAR_MODULO'
export const GUARDANDO_SESIONES_EVENTOS_SELECT = 'GUARDANDO_SESIONES_EVENTOS_SELECT'
export const ACTUALIZAR_TAB_MENSAJERIA = 'ACTUALIZAR_TAP_MENSAJERIA'
export const ACTUALIZAR_HILO = 'ACTUALIZAR_HILO'
export const GUARDAR_GRUPO = "GUARDAR_GRUPO"
export const proyectosTypes = {
  NUEVO_PROYECTO: 'NUEVO_PROYECTO',
  NUEVO_PROYECTO_EXITO: 'NUEVO_PROYECTO_EXITO',
  NUEVO_PROYECTO_ERROR: 'NUEVO_PROYECTO_ERROR',
  NUEVO_PROYECTO_EXITO_RESET_REDIRECCION: 'NUEVO_PROYECTO_EXITO_RESET_REDIRECCION',
  EDITAR_PROYECTO_NIVEL_ACADEMICO: 'EDITAR_PROYECTO_NIVEL_ACADEMICO',
  EDITAR_PROYECTO_NIVEL_ACADEMICO_EXITO: 'EDITAR_PROYECTO_NIVEL_ACADEMICO_EXITO',
  EDITAR_PROYECTO_NIVEL_ACADEMICO_ERROR: 'EDITAR_PROYECTO_NIVEL_ACADEMICO_ERROR',
  EDITAR_PROYECTO_HORARIO: 'EDITAR_PROYECTO_HORARIO',
  EDITAR_PROYECTO_HORARIO_EXITO: 'EDITAR_PROYECTO_HORARIO_EXITO',
  EDITAR_PROYECTO_HORARIO_ERROR: 'EDITAR_PROYECTO_HORARIO_ERROR', 
  OBTENER_PROYECTO: 'OBTENER_PROYECTO',
  OBTENER_PROYECTO_EXITO: 'OBTENER_PROYECTO_EXITO',
  OBTENER_PROYECTO_ERROR: 'OBTENER_PROYECTO_ERROR',
  ACTUALIZAR_NIVEL_ACADEMICO: 'ACTUALIZAR_NIVEL_ACADEMICO',
  ACTUALIZAR_NIVEL_ACADEMICO_EXITO: 'ACTUALIZAR_NIVEL_ACADEMICO_EXITO',
  ACTUALIZAR_NIVEL_ACADEMICO_ERROR: 'ACTUALIZAR_NIVEL_ACADEMICO_ERROR',
  NUEVO_PLAN: 'NUEVO_PLAN',
  NUEVO_PLAN_EXITO: 'NUEVO_PLAN_EXITO',
  NUEVO_PLAN_ERROR: 'NUEVO_PLAN_ERROR',
  GUARDAR_PENSION: 'GUARDAR_PENSION',
  GUARDAR_PENSION_EXITO: 'GUARDAR_PENSION_EXITO',
  GUARDAR_PENSION_ERROR: 'GUARDAR_PENSION_ERROR',
  AGREGAR_MODULO_PROYECTO: 'AGREGAR_MODULO_PROYECTO',
  AGREGAR_MODULO_PROYECTO_EXITO: 'AGREGAR_MODULO_PROYECTO_EXITO',
  AGREGAR_MODULO_PROYECTO_ERROR: 'AGREGAR_MODULO_PROYECTO_ERROR',
  EDITAR_PLAN_PROYECTO: 'EDITAR_PLAN_PROYECTO',
  EDITAR_PLAN_PROYECTO_EXITO: 'EDITAR_PLAN_PROYECTO_EXITO',
  EDITAR_PLAN_PROYECTO_ERROR: 'EDITAR_PLAN_PROYECTO_ERROR', 
  ELIMINAR_PLAN_PROYECTO: 'ELIMINAR_PLAN_PROYECTO',
  ELIMINAR_PLAN_PROYECTO_EXITO: 'ELIMINAR_PLAN_PROYECTO_EXITO', 
  ELIMINAR_PLAN_PROYECTO_ERROR: 'ELIMINAR_PLAN_PROYECTO_ERROR',
  ELIMINAR_MODULO_PROYECTO: 'ELIMINAR_MODULO_PROYECTO',
  ELIMINAR_MODULO_PROYECTO_EXITO: 'ELIMINAR_MODULO_PROYECTO_EXITO',
  ELIMINAR_MODULO_PROYECTO_ERROR: 'ELIMINAR_MODULO_PROYECTO_ERROR'
}

export const modulosTypes = {
  OBTENER_MODULOS: 'OBTENER_MODULOS',
  OBTENER_MODULOS_EXITO: 'OBTENER_MODULOS_EXITO',
  OBTENER_MODULOS_ERROR: 'OBTENER_MODULOS_ERROR',
  NUEVO_MODULO: 'NUEVO_MODULO',
  NUEVO_MODULO_EXITO: 'NUEVO_MODULO_EXITO',
  NUEVO_MODULO_ERROR: 'NUEVO_MODULO_ERROR',
  EDITAR_MODULO: 'EDITAR_MODULO',
  EDITAR_MODULO_EXITO: 'EDITAR_MODULO_EXITO',
  EDITAR_MODULO_ERROR: 'EDITAR_MODULO_ERROR',
  ELIMINAR_MODULO: 'ELIMINAR_MODULO',
  ELIMINAR_MODULO_EXITO: 'ELIMINAR_MODULO_EXITO',
  ELIMINAR_MODULO_ERROR: 'ELIMINAR_MODULO_ERROR'
}


export const boletasTypes = {
  OBTENER_BOLETAS: 'OBTENER_BOLETAS',
  OBTENER_BOLETAS_EXITO: 'OBTENER_BOLETAS_EXITO',
  FIRMAR_BOLETAS: 'FIRMAR_BOLETAS',
}
export const basesCertTypes = {
  OBTENER_BASES: 'OBTENER_BASES',
  OBTENER_BASES_EXITO: 'OBTENER_BASES_EXITO',
  FIRMAR_CERTIFICADOS: 'FIRMAR_CERTIFICADOS',
  OBTENER_APTOS:'OBTENER_APTOS',
  OBTENER_APTOS_EXITO:'OBTENER_APTOS_EXITO',
  OBTENER_EMITIDOS:'OBTENER_EMITIDOS',
  OBTENER_EMITIDOS_EXITO:'OBTENER_EMITIDOS_EXITO',
}

export const matriculasTypes = {
  NUEVA_MATRICULA: 'NUEVA_MATRICULA',
  NUEVA_MATRICULA_EXITO: 'NUEVA_MATRICULA_EXITO',
  NUEVA_MATRICULA_ERROR: 'NUEVA_MATRICULA_ERROR',
  OBTENER_TODAS_MATRICULAS: 'OBTENER_TODAS_MATRICULAS',
  OBTENER_TODAS_MATRICULAS_EXITO: 'OBTENER_TODAS_MATRICULAS_EXITO',
  OBTENER_TODAS_MATRICULAS_ERROR: 'OBTENER_TODAS_MATRICULAS_ERROR',
  OBTENER_MATRICULA_DISCENTE: 'OBTENER_MATRICULA_DISCENTE',
  LIMPIAR_RESULTADO_MATRICULAS: 'LIMPIAR_RESULTADO_MATRICULAS',
  OBTENER_MATRICULA_DISCENTE_EXITO: 'OBTENER_MATRICULA_DISCENTE_EXITO',
  OBTENER_MATRICULA_DISCENTE_ERROR: 'OBTENER_MATRICULA_DISCENTE_ERROR',
  EDITAR_ESTADO_MATRICULA_DISCENTE: 'EDITAR_ESTADO_MATRICULA_DISCENTE',
  EDITAR_ESTADO_MATRICULA_DISCENTE_EXITO: 'EDITAR_ESTADO_MATRICULA_DISCENTE_EXITO',
  EDITAR_ESTADO_MATRICULA_DISCENTE_ERROR: 'EDITAR_ESTADO_MATRICULA_DISCENTE_ERROR',
  ELIMINAR_MATRICULA: 'ELIMINAR_MATRICULA',
  ELIMINAR_MATRICULA_EXITO: 'ELIMINAR_MATRICULA_EXITO'
}

export const ELIMINAR_PROYECTO = 'ELIMINAR_PROYECTO'
export const LISTAR_PROYECTOS_EXITO = 'LISTAR_PROYECTOS_EXITO'
export const LISTAR_PROYECTO = 'LISTAR_PROYECTO'
export const REMOVER_PROYECTO = 'REMOVER_PROYECTO'
export const REMOVER_STATE_MODULO = 'REMOVER_STATE_MODULO'
export const LISTAR_MODULO_PROYECTO = 'LISTAR_MODULO_PROYECTO'
export const LISTAR_MODULOS_PROYECTO_TODO = 'LISTAR_MODULOS_PROYECTO_TODO'
//Docentes
export const LISTAR_PROFESORES = 'LISTAR_PROFESORES';
export const LISTAR_CURSOS_PROFESORES = 'LISTAR_CURSOS_PROFESORES'
export const LISTAR_PROFESOR = 'LISTAR_DOCENTE';
export const OBTENER_DOCENTE_EXITO = 'OBTENER_DOCENTE_EXITO';
export const INSERTAR_DOCENTE = 'INSERTAR_DOCENTE';
export const INSERTAR_DOCENTE_EXITO = 'INSERTAR_DOCENTE_EXITO';
export const INSERTAR_DOCENTE_ERROR = 'INSERTAR_DOCENTE_ERROR';
export const QUITAR_INSERTAR_DOCENTE_EXITO = 'QUITAR_INSERTAR_DOCENTE_EXITO';
export const LISTAR_PROFESORES_CURSO = "LISTAR_PROFESORES_CURSO";
export const INSERTAR_PROFESOR_CURSO = 'INSERTAR_PROFESOR_CURSO'; 
export const EDITAR_PROFESOR = 'EDITAR_PROFESOR' 
export const LISTAR_PROFESORES_FALTANTES = 'LISTAR_PROFESORES_FALTANTES'
export const LISTAR_PROFESORES_SESION = 'LISTAR_PROFESORES_SESION'
export const ELIMINAR_DOCENTE_CURSO = 'ELIMINAR_DOCENTE_CURSO'; 
export const INSERTAR_PROFESOR_SESION = "INSERTAR_PROFESOR_SESION"; 
export const ELIMINAR_PROFESOR = 'ELIMINAR_PROFESOR'
export const ELIMINAR_DOCENTE_SESION = 'ELIMINAR_DOCENTE_SESION';
export const AGREGAR_INDICADOR_DOCENTE_EXITO = 'AGREGAR_INDICADOR_DOCENTE_EXITO';
export const ELIMINAR_INDICADOR_DOCENTE = 'ELIMINAR_INDICADOR_DOCENTE';
export const ELIMINAR_INDICADOR_DOCENTE_EXITO = 'ELIMINAR_INDICADOR_DOCENTE_EXITO';
export const OBTERNER_TEMAS_AREA_EXITO = 'OBTERNER_TEMAS_AREA_EXITO';
export const OBTENER_TEMAS_AREA_EXITO = 'OBTENER_TEMAS_AREA_EXITO';
export const AREA_DOCENTE_ACTIVO = 'AREA_DOCENTE_ACTIVO';
export const ASIGNAR_AREA_DICTADO_DOCENTE = 'ASIGNAR_AREA_DICTADO_DOCENTE';
export const ASIGNAR_TEMA_DOCENTE = 'ASIGNAR_TEMA_DOCENTE';
export const GUARDAR_NUEVO_ORDEN_TEMAS = 'GUARDAR_NUEVO_ORDEN_TEMAS';
export const ELIMINAR_TEMA_DOCENTE = 'ELIMINAR_TEMA_DOCENTE';
export const ELIMINAR_TEMA_DOCENTE_EXITO = 'ELIMINAR_TEMA_DOCENTE_EXITO';
export const GUARDAR_CV_DOCENTE = 'GUARDAR_CV_DOCENTE';
export const ARCHIVO_ADJUNTO_SUBIDO = 'ARCHIVO_ADJUNTO_SUBIDO';
export const ELIMINAR_ARCHIVO_DOCENTE = 'ELIMINAR_ARCHIVO_DOCENTE';
export const EDITAR_HONORARIO_DOCENTE = 'EDITAR_HONORARIO_DOCENTE';
export const EDITAR_PASSWORD_DOCENTE = 'EDITAR_PASSWORD_DOCENTE';
export const EDITAR_USUARIO_DOCENTE = 'EDITAR_USUARIO_DOCENTE';
export const EDITAR_REGISTRO_DOCENTE = 'EDITAR_REGISTRO_DOCENTE';
export const SUBIR_AVATAR_DOCENTE = 'SUBIR_AVATAR_DOCENTE';
//ESTUDIANTES
export const estudianteTypes = {
  OBTENER_ESTUDIANTE: 'OBTENER_ESTUDIANTE',
  OBTENER_ESTUDIANTE_EXITO: 'OBTENER_ESTUDIANTE_EXITO',
  OBTENER_ESTUDIANTE_ERROR: 'OBTENER_ESTUDIANTE_ERROR'
}
export const LISTAR_ESTUDIANTES = 'LISTAR_ESTUDIANTES';
export const MOSTRAR_ESTUDIANTE = 'MOSTRAR_ESTUDIANTE';
export const INSERTAR_ESTUDIANTES = 'INSERTAR_ESTUDIANTES';
export const REMOVER_ESTADO = 'REMOVER_ESTADO'; 
export const LISTAR_ESTUDIANTE = 'LISTAR_ESTUDIANTE';
export const EDITAR_ESTUDIANTE = 'EDITAR_ESTUDIANTE' 
export const ELIMINAR_ESTUDIANTE = 'ELIMINAR_ESTUDIANTE'
export const LISTAR_PROYECTOS_ESTUDIANTES = 'LISTAR_PROYECTOS_ESTUDIANTES' 
export const LISTAR_ESTUDANTE_PROYECTO = 'LISTAR_ESTUDANTE_PROYECTO'
export const LISTAR_MODULOS_ESTUDIANTES = 'LISTAR_MODULOS_ESTUDIANTES'
export const REMOVER_MATRICULAR_PROYECTO = 'REMOVER_MATRICULAR_PROYECTO'
export const LISTAR_MODULOS_COMPRADOS = 'LISTAR_MODULOS_COMPRADOS'
export const ELIMINAR_MATRICUA_MODULO = 'ELIMINAR_MATRICUA_MODULO'
export const SELECT_ESTUDIANTE_OBTENIDO = 'SELECT_ESTUDIANTE_OBTENIDO'
export const REMOVER_STATE_ESTUDIANTE = 'REMOVER_STATE_ESTUDIANTE' 
//SESIONES
export const INSERTAR_SESIONES_MASIVA = 'INSERTAR_SESIONES_MASIVA' 
export const INSERTAR_SESIONES_MANUAL = 'INSERTAR_SESIONES_MANUAL' 
export const LISTAR_SESION = 'LISTAR_SESION'
export const LISTAR_SESIONES_MODULO = 'LISTAR_SESIONES_MODULO' 
export const ELIMINAR_SESION = 'ELIMINAR_SESION'
export const EDITAR_SESION = 'EDITAR_SESION'
export const REMOVER_MODULO = 'REMOVER_MODULO'
export const REMOVER_SESION = 'REMOVER_SESION'
export const REMOVER_SESIONES_MODULO = 'REMOVER_SESIONES_MODULO'
//RECURSOS 
export const SUBIR_RECURSO = 'SUBIR_RECURSO' 
export const LISTAR_ARCHIVOS = 'LISTAR_ARCHIVOS'
export const ELIMINAR_RECURSO = 'ELIMINAR_RECURSO'
export const ELIMINAR_VIDEO = 'ELIMINAR_VIDEO'
export const INSERTAR_VIDEOS = 'INSERTAR_VIDEOS'
export const LISTAR_VIDEOS = 'LISTAR_VIDEOS'
export const EDITANDO_RECURSO = 'EDITANDO_RECURSO'
export const RECURSO_EDITADO = 'RECURSO_EDITADO'
export const ERROR_CONSULTA = 'ERROR_CONSULTA'

//NOTAS
export const LISTAR_NOTAS = 'LISTAR_NOTAS'
export const EDITAR_NOTAS = 'EDITAR_NOTAS'
export const REMOVER_ESTADO_NOTAS = 'REMOVER_ESTADO_NOTAS'
//PAGOS

export const temasTypes = {
  SHOW_FORM: 'SHOW_FORM',
  OBTENER_TEMAS: 'OBTENER_TEMAS',
  OBTENER_TEMAS_EXITO : 'OBTENER_TEMAS_EXITO',
  ACTUALIZAR_TEMA_EXITO : 'ACTUALIZAR_TEMA_EXITO',
  ELIMINAR_TEMA_EXITO : 'ELIMINAR_TEMA_EXITO',
  GUARDAR_TEMA : 'GUARDAR_TEMA',
  ELIMINAR_TEMA : 'ELIMINAR_TEMA',
  OBTENER_TEMA_EXITO : 'OBTENER_TEMA_EXITO',
  OBTENER_VIDEO_EXITO : 'OBTENER_VIDEO_EXITO',
}

export const pagosTypes = {
  UPDATE_FORMULARIO_BUSQUEDA: 'UPDATE_FORMULARIO_BUSQUEDA', 
  OBTENER_MATRICULAS_DISCENTE: 'OBTENER_MATRICULAS_DISCENTE',
  OBTENER_MATRICULAS_DISCENTE_EXITO: 'OBTENER_MATRICULAS_DISCENTE_EXITO',
  OBTENER_MATRICULAS_DISCENTE_NO_ENCONTRADO: 'OBTENER_MATRICULAS_DISCENTE_NO_ENCONTRADO',
  OBTENER_MATRICULAS_DISCENTE_ERROR: 'OBTENER_MATRICULAS_DISCENTE_ERROR',
  MATRICULA_ACTIVA_DISCENTE: 'MATRICULA_ACTIVA_DISCENTE',
  OBTENER_CUOTAS_FRACCIONADAS: 'OBTENER_CUOTAS_FRACCIONADAS',
  OBTENER_CUOTAS_FRACCIONADAS_EXITO: 'OBTENER_CUOTAS_FRACCIONADAS_EXITO', 
  OBTENER_CUOTAS_FRACCIONADAS_ERROR: 'OBTENER_CUOTAS_FRACCIONADAS_ERROR',
  INSERTAR_CUOTA_FRACCIONADA: 'INSERTAR_CUOTA_FRACCIONADA',
  INSERTAR_CUOTA_FRACCIONADA_EXITO: 'INSERTAR_CUOTA_FRACCIONADA_EXITO',
  INSERTAR_CUOTA_FRACCIONADA_ERROR: 'INSERTAR_CUOTA_FRACCIONADA_ERROR',
  ACTUALIZAR_TIPO_PENSION: 'ACTUALIZAR_TIPO_PENSION',
  ACTUALIZADO_ESTADOS_TABLA_CUOTAS: 'ACTUALIZADO_ESTADOS_TABLA_CUOTAS',
  PAGAR_CUOTA: 'PAGAR_CUOTA',
  PAGAR_CUOTA_EXITO: 'PAGAR_CUOTA_EXITO',
  PAGAR_CUOTA_ERROR: 'PAGAR_CUOTA_ERROR',
  PAGAR_CUOTA_EXTRA: 'PAGAR_CUOTA_EXTRA',
  PAGAR_CUOTA_EXTRA_EXITO: 'PAGAR_CUOTA_EXTRA_EXITO',
  PAGAR_CUOTA_EXTRA_ERROR: 'PAGAR_CUOTA_EXTRA_ERROR',
  OBTENER_ID_BOLETA_SIGUIENTE: 'OBTENER_ID_BOLETA_SIGUIENTE',
  OBTENER_ID_BOLETA_SIGUIENTE_EXITO: 'OBTENER_ID_BOLETA_SIGUIENTE_EXITO',
  OBTENER_ID_BOLETA_SIGUIENTE_ERROR: 'OBTENER_ID_BOLETA_SIGUIENTE_ERROR',
  REGISTRAR_DETALLES_PAGOS: 'REGISTRAR_DETALLES_PAGOS',
  REGISTRAR_PAGO_EXTRAORDINARIO: 'REGISTRAR_PAGO_EXTRAORDINARIO',
  REGISTRAR_PAGO_EXTRAORDINARIO_EXITO: 'REGISTRAR_PAGO_EXTRAORDINARIO_EXITO',
  REGISTRAR_PAGO_EXTRAORDINARIO_ERROR: 'REGISTRAR_PAGO_EXTRAORDINARIO_ERROR',
  ELIMINAR_CUOTA_FRACCION: 'ELIMINAR_CUOTA_FRACCION',
  ELIMINAR_CUOTA_FRACCION_EXITO: 'ELIMINAR_CUOTA_FRACCION_EXITO',
  ELIMINAR_CUOTA_EXTRA_EXITO: 'ELIMINAR_CUOTA_EXTRA_EXITO',
  ELIMINAR_CUOTA_FRACCION_ERROR: 'ELIMINAR_CUOTA_FRACCION_EXITO_ERROR',
  ANULAR_PAGO_ORDINARIO: 'ANULAR_PAGO_ORDINARIO',
  ANULAR_PAGO_ORDINARIO_EXITO: 'ANULAR_PAGO_ORDINARIO_EXITO',
  ANULAR_PAGO_ERROR: 'ANULAR_PAGO_ERROR',
  ANULAR_PAGO_EXTRA: 'ANULAR_PAGO_EXTRA',
  ANULAR_PAGO_EXTRA_EXITO: 'ANULAR_PAGO_EXTRA_EXITO',
  NUEVO_DESCUENTO_CARGO: 'NUEVO_DESCUENTO_CARGO',
  NUEVO_DESCUENTO_CARGO_EXITO: 'NUEVO_DESCUENTO_CARGO_EXITO',
  NUEVO_DESCUENTO_CARGO_ERROR: 'NUEVO_DESCUENTO_CARGO_ERROR',
  ELIMINAR_DESCUENTO_CARGO_EXITO: 'ELIMINAR_DESCUENTO_CARGO_EXITO',
  OBTENER_MOTIVOS_DESCUENTOS: 'OBTENER_MOTIVOS_DESCUENTOS',
  OBTENER_MOTIVOS_DESCUENTOS_EXITO: 'OBTENER_MOTIVOS_DESCUENTOS_EXITO',
  OBTENER_MOTIVOS_DESCUENTOS_ERROR: 'OBTENER_MOTIVOS_DESCUENTOS_ERROR',
  OBTENER_CONCEPTOS_PAGOS: 'OBTENER_CONCEPTOS_PAGOS',
  OBTENER_CONCEPTOS_PAGOS_EXITO: 'OBTENER_CONCEPTOS_PAGOS_EXITO',
  OBTENER_CONCEPTOS_PAGOS_ERROR: 'OBTENER_CONCEPTOS_PAGOS_ERROR'
}

export const finanzasReportesTypes = {
  OBTENER_REPORTE_INGRESOS_FINANZAS: 'OBTENER_REPORTE_INGRESOS_FINANZAS',
  OBTENER_REPORTE_INGRESOS_FINANZAS_EXITO: 'OBTENER_REPORTE_INGRESOS_FINANZAS_EXITO',
  OBTENER_REPORTE_INGRESOS_FINANZAS_ERROR: 'OBTENER_REPORTE_INGRESOS_FINANZAS_ERROR',
  LIMPIAR_RESULTADO_BUSQUEDA: 'LIMPIAR_RESULTADO_BUSQUEDA',
  OBTENER_REPORTE_DEUDAS_FINANZAS: 'OBTENER_REPORTE_DEUDAS_FINANZAS',
  OBTENER_REPORTE_DEUDAS_FINANZAS_EXITO: 'OBTENER_REPORTE_DEUDAS_FINANZAS_EXITO',
  OBTENER_REPORTE_DEUDAS_FINANZAS_ERROR: 'OBTENER_REPORTE_DEUDAS_FINANZAS_ERROR',
  OBTENER_FACTURAS: 'OBTENER_FACTURAS',
  OBTENER_FACTURAS_EXITO: 'OBTENER_FACTURAS_EXITO',
  OBTENER_FACTURAS_ERROR: 'OBTENER_FACTURAS_ERROR',
  OBTENER_DATOS_FACTURA: 'OBTENER_DATOS_FACTURA',
  OBTENER_DATOS_FACTURA_EXITO: 'OBTENER_DATOS_FACTURA_EXITO',
  OBTENER_DATOS_FACTURA_ERROR: 'OBTENER_DATOS_FACTURA_ERROR',
  OBTENER_REPORTE_CARGOS_DESCUENTOS: 'OBTENER_REPORTE_CARGOS_DESCUENTOS',
  OBTENER_REPORTE_CARGOS_EXITO: 'OBTENER_REPORTE_CARGOS_EXITO',
  OBTENER_REPORTE_DESCUENTOS_EXITO: 'OBTENER_REPORTE_DESCUENTOS_EXITO',
  OBTENER_REPORTE_CARGOS_DESCUENTOS_ERROR: 'OBTENER_REPORTE_CARGOS_DESCUENTOS_ERROR'
}
export const reporteNaranjaTypes = {
  OBTENER_REPORTE_NARANJA: 'OBTENER_REPORTE_NARANJA',
  OBTENER_REPORTE_NARANJA_EXITO: 'OBTENER_REPORTE_NARANJA_EXITO',
  OBTENER_REPORTE_NARANJA_ERROR: 'OBTENER_REPORTE_NARANJA_ERROR',
  OBTENER_REPORTE_RECURRENTE: 'OBTENER_REPORTE_RECURRENTE',
  OBTENER_REPORTE_RECURRENTE_EXITO: 'OBTENER_REPORTE_RECURRENTE_EXITO',
  OBTENER_REPORTE_RECURRENTE_ERROR: 'OBTENER_REPORTE_RECURRENTE_ERROR',
  LIMPIAR_RESULTADO_BUSQUEDA: 'LIMPIAR_RESULTADO_BUSQUEDA'
 
}

export const reporteCalificacionTypes = {
  OBTENER_REPORTE_CALIFICACION: 'OBTENER_REPORTE_CALIFICACION',
  OBTENER_REPORTE_CALIFICACION_EXITO: 'OBTENER_REPORTE_CALIFICACION_EXITO',
  OBTENER_REPORTE_CALIFICACION_ERROR: 'OBTENER_REPORTE_CALIFICACION_ERROR',
  LIMPIAR_RESULTADO_BUSQUEDA: 'LIMPIAR_RESULTADO_BUSQUEDA'
}

export const indicadoresTypes = {
  OBTENER_INDICADORES: 'OBTENER_INDICADORES',
  OBTENER_INDICADORES_EXITO: 'OBTENER_INDICADORES_EXITO',
  OBTENER_INDICADORES_ERROR: 'OBTENER_INDICADORES_ERROR',
  LIMPIAR_INDICADORES_BUSQUEDA: 'LIMPIAR_INDICADORES_BUSQUEDA'
}

export const LISTAR_DETALLES_PAGOS = 'LISTAR_DETALLES_PAGOS' 
export const ACTUALIZAR_PAGO = "ACTUALIZAR_PAGO";
export const LISTAR_EVENTOS_COMPRADOS = 'LISTAR_EVENTOS_COMPRADOS' 
export const REMOVER_INFORMACION_PAGOS = 'REMOVER_INFORMACION_PAGOS'
export const EDITAR_ESTADO_PAGOS_C = 'EDITAR_ESTADO_PAGOS_C'
export const EDITAR_DETALLES_PAGOS = 'EDITAR_DETALLES_PAGOS'
//MENSAJES
export const LISTAR_MENSAJES = "LISTAR_MENSAJES";
export const LISTAR_MENSAJES_ENVIADOS = "LISTAR_MENSAJES_ENVIADOS";
export const MOSTRAR_MENSAJE = "MOSTRAR_MENSAJE";
export const ENVIAR_MENSAJE = "ENVIAR_MENSAJE";
export const MANDAR_MENSAJE_R_PAPELERA = 'MANDAR_MENSAJE_R_PAPELERA'
export const MANDAR_MENSAJE_E_PAPELERA = 'MANDAR_MENSAJE_E_PAPELERA'
export const RESTAURAR_MENSAJE = 'RESTAURAR_MENSAJE'
export const ELIMINAR_MENSAJE = 'ELIMINAR_MENSAJE'
export const LIMPIAR_MENSAJE = 'LIMPIAR_MENSAJE'
export const LISTAR_PAPELERA = 'LISTAR_PAPELERA'
//Usuario
export const MOSTRAR_USUARIO = 'MOSTRAR_USUARIO'
export const REMOVER_USUARIO = "REMOVER_USUARIO";
export const USUARIO_TRAER = 'USUARIO_TRAER' 

//Examenes

export const examenesTypes = {
 LISTAR_EXAMENES:'LISTAR_EXAMENES',
 LISTAR_EXAMENES_EXITO: 'LISTAR_EXAMENES_EXITO',
 LISTAR_EXAMENES_ERROR: 'LISTAR_EXAMENES_ERROR',
 OBTENER_EXAMEN_SESION: 'OBTENER_EXAMEN_SESION',
 OBTENER_EXAMEN_SESION_EXITO: 'OBTENER_EXAMEN_SESION_EXITO',
 INSERTAR_EXAMEN: 'INSERTAR_EXAMEN',
 INSERTAR_EXAMEN_EXITO: 'INSERTAR_EXAMEN_EXITO',
 INSERTAR_EXAMEN_ERROR: 'INSERTAR_EXAMEN_ERROR',
 INSERTAR_PREGUNTA: "INSERTAR_PREGUNTA"
}


export const gruposAcademicosTypes = {
  LISTAR_GRUPOS_ACA:'LISTAR_GRUPOS_ACA',
  LISTAR_GRUPOS_ACA_EXITO: 'LISTAR_GRUPOS_ACA_EXITO',
  LISTAR_GRUPOS_ACA_EXITO_ERROR: 'LISTAR_GRUPOS_ACA_EXITO_ERROR',
  INSERTAR_INTEGRANTE: 'INSERTAR_INTEGRANTE',
  INSERTAR_INTEGRANTE_EXITO: 'INSERTAR_INTEGRANTE_EXITO',
  INSERTAR_INTEGRANTE_ERROR: 'INSERTAR_INTEGRANTE_ERROR',
  LISTAR_INTEGRANTES:'LISTAR_INTEGRANTES',
  LISTAR_INTEGRANTES_EXITO:'LISTAR_INTEGRANTES_EXITO',
  LISTAR_ESTUDIANTES_PRODUCTO:'LISTAR_ESTUDIANTES_PRODUCTO',
  LISTAR_ESTUDIANTES_PRODUCTO_EXITO:'LISTAR_ESTUDIANTES_PRODUCTO_EXITO'

}
 
export const comunicadostypes = {
  LISTAR_COMUNICADOS:"LISTAR_COMUNICADOS",
  LISTAR_COMUNICADOS_EXITO:"LISTAR_COMUNICADOS_EXITO",
  ELIMINAR_COMUNICADO:"ELIMINAR_COMUNICADO"
 }
 export const leadstypes = {
  CREAR_CONSTANCIA: 'CREAR_CONSTANCIA',
  CREAR_CONSTANCIA_EXITO: 'CREAR_CONSTANCIA_EXITO',
  CREAR_CONSTANCIA_ERROR: 'CREAR_CONSTANCIA_ERROR',
  LISTAR_LEADS:"LISTAR_LEADS",
  LISTAR_LEADS_EXITO:"LISTAR_LEADS_EXITO",
  IMPORTAR_LEADS:"IMPORTAR_LEADS",
  IMPORTAR_LEADS_EXITO:"IMPORTAR_LEADS_EXITO",
  ELIMINAR_LEAD:"ELIMINAR_LEAD",
  LISTAR_ASESORES:"LISTAR_ASESORES",
  LISTAR_FUENTES:"LISTAR_FUENTES",
  LISTAR_ESTADOS_LEAD:"LISTAR_ESTADOS_LEAD",
  LISTAR_FASES:"LISTAR_FASES",
  LISTAR_OBSERVACIONES_LEAD:"LISTAR_OBSERVACIONES_LEAD",
  FILTRAR_LEADS:"FILTRAR_LEADS",
  FILTRAR_CONTACTOS:"FILTRAR_CONTACTOS",
  LISTAR_TRATOS:"LISTAR_TRATOS",
  NUEVA_LLAMADA:"NUEVA_LLAMADA",
  DETALLE_LEAD_EXITO:"DETALLE_LEAD_EXITO",
  TRAER_DETALLE_LEAD:"TRAER_DETALLE_LEAD",
 }
 export const campaigntypes = {
  LISTAR_CAMPAIGNS:"LISTAR_CAMPAIGNS",
  LISTAR_CAMPAIGNS_EXITO:"LISTAR_CAMPAIGNS_EXITO",
  LISTAR_TIPOS_RECURSO_EXITO:"LISTAR_TIPOS_RECURSO_EXITO",
  INSERTAR_CAMPAING:"INSERTAR_CAMPAING",
  ACTUALIZAR_CAMPAIGN:"ACTUALIZAR_CAMPAIGN",
  ELIMINAR_CAMPAIGN:"ELIMINAR_CAMPAIGN",
  LISTAR_RECURSOS:"LISTAR_RECURSOS",
  LISTAR_RECURSOS_EXITO:"LISTAR_RECURSOS_EXITO",
  LISTAR_ANUNCIOS:"LISTAR_ANUNCIOS",
  LISTAR_ANUNCIOS_EXITO:"LISTAR_ANUNCIOS_EXITO",
  LISTAR_WEBINARS:"LISTAR_WEBINARS",
  LISTAR_WEBINARS_EXITO:"LISTAR_WEBINARS_EXITO",
  TOTALES:"TOTALES",
  RESUMEN_WEBINAR:"RESUMEN_WEBINAR",
  CONFIGURACION_CAMPAIGN:"CONFIGURACION_CAMPAIGN",
  ACTUALIZAR_COMENTARIO:"ACTUALIZAR_COMENTARIO",
 }

 export const respuestasComentariostypes = {
  LISTAR_COMENTARIOS:"LISTAR_COMENTARIOS",
  LISTAR_COMENTARIOS_EXITO:"LISTAR_COMENTARIOS_EXITO",
  ELIMINAR_RESPUESTA:"ELIMINAR_RESPUESTA"
 }
//Asistencias
export const INSERTAR_ASISTENCIA = 'INSERTAR_ASISTENCIA';
export const LISTAR_CONTACTOS = 'LISTAR_CONTACTOS'
export const REMOVER_ASSITENCIAS_INFO = 'REMOVER_ASSITENCIAS_INFO'
export const EDITAR_ASISTENCIA = 'EDITAR_ASISTENCIA'
export const asistenciaTypes = {
  OBTENER_ASISTENCIAS: 'OBTENER_ASISTENCIAS',
  OBTENER_ASISTENCIAS_EXITO: 'OBTENER_ASISTENCIAS_EXITO',
  OBTENER_ASISTENCIAS_ERROR: 'OBTENER_ASISTENCIAS_ERROR',
  ACTUALIZAR_ESTADO_ASISTENCAS_MASIVO: 'ACTUALIZAR_ESTADO_ASISTENCAS_MASIVO',
  ACTUALIZAR_ESTADO_ASISTENCAS_MASIVO_EXITO: 'ACTUALIZAR_ESTADO_ASISTENCAS_MASIVO_EXITO',
  ACTUALIZAR_ESTADO_ASISTENCAS_MASIVO_ERROR: 'ACTUALIZAR_ESTADO_ASISTENCAS_MASIVO_ERROR'
}

//Trabajadores
export const LISTAR_USUARIOS_ACCESO = 'LISTAR_USUARIOS_ACCESO'
export const INSERTAR_USUARIO = 'INSERTAR_USUARIO'
export const ELIMINAR_USUARIO_SISTEMA = 'ELIMINAR_USUARIO_SISTEMA'
export const LISTAR_USAURIO_SISTEMA = 'LISTAR_USAURIO_SISTEMA'
export const CARGAR_CUMPLES = 'CARGAR_CUMPLES'
export const EDITAR_USUARIO_SISTEMA = 'EDITAR_USUARIO_SISTEMA' 
export const EDITAR_NOMBRE_USUARIO_SISTEMA = 'EDITAR_NOMBRE_USUARIO_SISTEMA'
export const EDITAR_CLAVE_USUARIO_SISTEMA = 'EDITAR_CLAVE_USUARIO_SISTEMA'
export const LISTAR_TRABAJADORES_SISTEMA = 'LISTAR_TRABAJADORES_SISTEMA';
export const LISTAR_TRABAJADORES_SISTEMA_ERROR = 'LISTAR_USAURIO_SISTEMA_ERROR'
export const LISTAR_TRABAJADORES_SISTEMA_EXITO = 'LISTAR_USAURIO_SISTEMA_EXITO'
export const ELIMINAR_TRABAJADOR_SISTEMA = 'ELIMINAR_TRABAJADOR_SISTEMA'
export const INSERTAR_NUEVO_TRABAJADOR = 'INSERTAR_NUEVO_TRABAJADOR'
export const OBTENER_TRABAJADOR = 'OBTENER_TRABAJADOR';
export const OBTENER_TRABAJADOR_EXITO = 'OBTENER_TRABAJADOR_EXITO'
export const OBTENER_TRABAJADOR_ERROR = 'OBTENER_TRABAJADOR_ERROR'
export const EDITAR_CLASIFICACION_TRABAJADOR = 'EDITAR_CLASIFICACION_TRABAJADOR';
export const EDITAR_CV_TRABAJADOR = 'EDITAR_CV_TRABAJADOR';
export const EDITAR_HONORARIOS_TRABAJADOR = 'EDITAR_HONORARIOS_TRABAJADOR';
export const ARCHIVO_ADJUNTO_SUBIDO_TRABAJADOR = 'ARCHIVO_ADJUNTO_SUBIDO_TRABAJADOR';
export const ELIMINAR_ARCHIVO_TRABAJADOR = 'ELIMINAR_ARCHIVO_TRABAJADOR';
export const EDITAR_NOMBRE_USUARIO_TRABAJADOR = 'EDITAR_NOMBRE_USUARIO_TRABAJADOR';
export const EDITAR_PASSWORD_TRABAJADOR = 'EDITAR_PASSWORD_TRABAJADOR';
export const EDITAR_REGISTRO_TRABAJADOR = 'EDITAR_REGISTRO_TRABAJADOR';
export const ACTUALIZAR_AVATAR_TRABAJADOR = 'ACTUALIZAR_AVATAR_TRABAJADOR';
export const ACTUALIZAR_AVATAR_USUARIO = 'ACTUALIZAR_AVATAR_USUARIO';
export const AREA_TRABAJADOR_ACTIVO = 'AREA_TRABAJADOR_ACTIVO';
export const AREAS_TRABAJADOR_OBTENIDO = 'AREAS_TRABAJADOR_OBTENIDO';
export const AGREGAR_TEMA_TRABAJADOR = 'AGREGAR_TEMA_TRABAJADOR';
export const ELIMINAR_TEMA_TRABAJADOR = 'ELIMINAR_TEMA_TRABAJADOR';
export const GUARDAR_NUEVO_ORDEN_TEMAS_TRABAJADOR = 'GUARDAR_NUEVO_ORDEN_TEMAS_TRABAJADOR';
export const trabajadoresTypes = {
  OBTENER_LISTA_CLASIFICACION: 'OBTENER_LISTA_CLASIFICACION',
  OBTENER_LISTA_CLASIFICACION_EXITO: 'OBTENER_LISTA_CLASIFICACION_EXITO',
  OBTENER_LISTA_CLASIFICACION_ERROR: 'OBTENER_LISTA_CLASIFICACION_ERROR' ,
  EDITAR_CONFIGURACION_AREA_TRABAJADOR: 'EDITAR_CONFIGURACION_AREA_TRABAJADOR',
  EDITAR_CONFIGURACION_AREA_TRABAJADOR_EXITO: 'EDITAR_CONFIGURACION_AREA_TRABAJADOR_EXITO',
  EDITAR_CONFIGURACION_AREA_TRABAJADOR_ERROR: 'EDITAR_CONFIGURACION_AREA_TRABAJADOR_ERROR',
  LISTAR_EQUIPO_ASESORES: 'LISTAR_EQUIPO_ASESORES',
  LISTAR_EQUIPO_ASESORES_EXITO: 'LISTAR_EQUIPO_ASESORES_EXITO',
  LISTAR_PRODUCTO_ASESORES: 'LISTAR_PRODUCTO_ASESORES',
  LISTAR_PRODUCTO_ASESORES_EXITO: 'LISTAR_PRODUCTO_ASESORES_EXITO',
}

//Empresa
export const LISTAR_INFO_EMPRESA = 'LISTAR_INFO_EMPRESA'
export const LISTAR_OFERTAS = 'LISTAR_OFERTAS'
export const LISTAR_OFERTAS_EXITO = 'LISTAR_OFERTAS_EXITO'
export const LISTAR_PROVEEDORES = 'LISTAR_PROVEEDORES'
export const LISTAR_PROVEEDORES_EXITO = 'LISTAR_PROVEEDORES_EXITO'
export const LISTAR_CONTRATOS_P = 'LISTAR_CONTRATOS_P'
export const LISTAR_CONTRATOS_P_EXITO = 'LISTAR_CONTRATOS_P_EXITO'

//Tareas
export const LISTAR_TAREAS = 'LISTAR_TAREAS'
export const LISTAR_TAREAS_ESTUDIANTES = 'LISTAR_TAREAS_ESTUDIANTES'
export const ELIMINAR_TAREAS = 'ELIMINAR_TAREAS'
export const INSERTAR_TAREAS = 'INSERTAR_TAREAS'

//POP_UPS
export const LISTAR_POP_UP = 'LISTAR_POP_UP'
export const LISTAR_FONDOS_INTRANET = 'LISTAR_FONDOS_INTRANET'
export const ELIMINAR_POP_UP = 'ELIMINAR_POP_UP'
export const ACTUALIZAR_POP_UP = 'ACTUALIZAR_POP_UP'
export const ACTUALIZAR_POP_UP_ERROR = 'ACTUALIZAR_POP_UP_ERROR'
export const OBTENIENDO_POPUP = 'OBTENIENDO_POPUP'
export const OBTENIENDO_POPUP_ERROR = 'OBTENIENDO_POPUP_ERROR'
export const SET_ID_PROVEEDOR = 'SET_ID_PROVEEDOR'


//Reportes
export const REPORTE_GENEREAL_POSVENTA = 'REPORTE_GENEREAL_POSVENTA'
export const EDITAR_REPORTE_ESTUDIANTE = 'EDITAR_REPORTE_ESTUDIANTE'
export const OBTENIENDO_REPORTE = 'OBTENIENDO_REPORTE' 
export const ERROR_OBTENER_REPORTE = 'ERROR_OBTENER_REPORTE'
export const ESTADO_DISCENTE_CAMBIADO = 'ESTADO_DISCENTE_CAMBIADO'
export const ELIMINADO_DISCENTE_TABLA_DESERTADOS = 'ELIMINADO_DISCENTE_TABLA_DESERTADOS'
export const ELIMINADO_DISCENTE_TABLA_SEGUIMIENTO = 'ELIMINADO_DISCENTE_TABLA_SEGUIMIENTO'
export const LIMPIAR_STATES_REPORTES = 'LIMPIAR_STATES_REPORTES'
export const NUEVO_LIMITE = 'NUEVO_LIMITE'
export const EDITANDO_REPORTE = 'EDITANDO_REPORTE'
export const REPORTE_EDITADO = 'REPORTE_EDITADO'
export const ERROR_EDITAR_REPORTE = 'ERROR_EDITAR_REPORTE'
export const REPORTE_ASISTENCIA_OBTENIDO = 'REPORTE_ASISTENCIA_OBTENIDO'
export const DATA_TABLA_ASISTENCIA_GENERADA = 'DATA_TABLA_ASISTENCIA_GENERADA'
export const OBTENIENDO_NOTAS_DISCENTES = 'OBTENIENDO_NOTAS_DISCENTES'
export const NOTAS_DISCENTE_OBTENIDO = 'NOTAS_DISCENTE_OBTENIDO'
export const TABLA_NOTAS_GENERADA = 'TABLA_NOTAS_GENERADA'
export const OBTENER_REPORTE_ENCUESTAS = 'OBTENER_REPORTE_ENCUESTAS'
export const OBTENER_REPORTE_ENCUESTAS_EXITO = 'OBTENER_REPORTE_ENCUESTAS_EXITO'
export const OBTENER_REPORTE_ENCUESTAS_ERROR = 'OBTENER_REPORTE_ENCUESTAS_ERROR'
export const SELECCION_INICIAL_TUTORIA = 'SELECCION_INICIAL_TUTORIA'

//Reprortes desertados
export const OBTENER_REPORTE_DESERTADOS = 'OBTENER_REPORTE_DESERTADOS'
export const REPORTE_DESERTADOS_EXITO = 'REPORTE_DESERTADOS_EXITO'
export const SET_SELECT_DESERTADOS = 'SET_SELECT_DESERTADOS'

//Tutores
export const OBTENIENDO_TUTORES = 'OBTENIENDO_TUTORES'
export const TUTORES_OBTENIDOS = 'TUTORES_OBTENIDOS'
export const ERROR_OBTENER_TUTORES = 'ERROR_OBTENER_TUTORES'
export const GUARDAR_TUTORES_SELECT = 'GUARDAR_TUTORES_SELECT'
export const OBTENIENDO_EVENTOS_TUTOR = 'OBTENIENDO_EVENTOS_TUTOR'
export const EVENTOS_TUTOR_OBTENIDO = 'EVENTOS_TUTOR_OBTENIDO'
export const ERROR_OBTENIENDO_EVENTOS = 'ERROR_OBTENIENDO_EVENTOS'
export const ELIMINAR_EVENTO_TUTOR = 'ELIMINAR_EVENTO_TUTOR'
export const ELIMINAR_EVENTO_TUTOR_EXITO = 'ELIMINAR_EVENTO_TUTOR_EXITO'
export const ELIMINAR_EVENTO_TUTOR_ERROR = 'ELIMINAR_EVENTO_TUTOR_ERROR'

//Encuestas
export const OBTENER_ENCUESTAS_DOCENTE = 'OBTENER_ENCUESTAS_DOCENTE'
export const OBTENER_ENCUESTAS_DOCENTE_EXITO = 'OBTENER_ENCUESTAS_DOCENTE_EXITO'
export const OBTENER_ENCUESTAS_DOCENTE_ERROR = 'OBTENER_ENCUESTAS_DOCENTE_ERROR'
export const OBTENIENDO_ENCUESTAS_TUTOR = 'OBTENIENDO_ENCUESTAS_TUTOR'
export const ENCUESTAS_OBTENIDAS = 'ENCUESTAS_OBTENIDAS'
export const ERROR_OBTENIENDO_ENCUESTAS = 'ERROR_OBTENIENDO_ENCUESTAS'
export const ELIMINANDO_ENCUESTA = 'ELIMINANDO_ENCUESTA'
export const ENCUESTA_ELIMINADO = 'ENCUESTA_ELIMINADO'
export const ERROR_ELIMINAR_ENCUESTA = 'ERROR_ELIMINAR_ENCUESTA'
export const OBTENIENDO_ENCUESTA_URL = 'OBTENIENDO_ENCUESTA_URL'
export const ENCUESTA_OBTENIDA = 'ENCUESTA_OBTENIDA'
export const ERROR_OBTENIENDO_ENCUESTA = 'ERROR_OBTENIENDO_ENCUESTA'
export const GUARDAR_ESTADO_ENCUESTA = 'GUARDAR_ESTADO_ENCUESTA'
export const CREANDO_NUEVA_ENCUESTA = 'CREANDO_NUEVA_ENCUESTA'
export const ERROR_CREANDO_ENCUESTA = 'ERROR_CREANDO_ENCUESTA'
export const NUEVA_ENCUESTA_CREADA = 'NUEVA_ENCUESTA_CREADA'
export const REMOVER_ESTADO_CREADO = 'REMOVER_ESTADO_CREADO'
export const GUARDANDO_ENCABEZADO = 'GUARDANDO_ENCABEZADO'
export const INSERTANDO_PREGUNTA = 'INSERTANDO_PREGUNTA'
export const GUARDANDO_PREGUNTA = 'GUARDANDO_PREGUNTA'
export const EDITANDO_PREGUNTA = 'EDITANDO_PREGUNTA'
export const GUARDANDO_CAMBIOS = 'GUARDANDO_CAMBIOS'
export const ELIMINANDO_PREGUNTA = 'ELIMINANDO_PREGUNTA'
export const OBTENIENDO_REPORTES_TUTOR = 'OBTENIENDO_REPORTES_TUTOR'
export const REPORTES_TUTOR_OBTENIDO = 'REPORTES_TUTOR_OBTENIDO'
export const OBTENIENDO_REPORTE_TUTOR = 'OBTENIENDO_REPORTE_TUTOR'
export const REPORTE_TUTOR_OBTENIDO = 'REPORTE_TUTOR_OBTENIDO'
export const GUARDANDO_REPORTE = 'GUARDANDO_REPORTE'
export const REPORTE_GUARDADO = 'REPORTE_GUARDADO'
export const REMOVER_ESTADO_GUARDADO_REPORTE = 'REMOVER_ESTADO_GUARDADO_REPORTE'
export const ERROR = 'ERROR'

//Apuntes
export const OBTERNET_APUNTES = 'OBTERNET_APUNTES'
export const OBTERNET_APUNTES_EXITO = 'OBTERNET_APUNTES_EXITO'
export const OBTERNET_APUNTES_ERROR = 'OBTERNET_APUNTES_ERROR'
export const GUARDAR_APUNTES = 'GUARDAR_APUNTES'
export const AGREGAR_APUNTE = 'AGREGAR_APUNTE'
export const GUARDAR_APUNTE_EXITO = 'GUARDAR_APUNTE_EXITO'
export const AGREGAR_APUNTE_EXITO = 'AGREGAR_APUNTE_EXITO'
export const AGREGAR_APUNTE_ERROR = 'AGREGAR_APUNTE_ERROR'
export const EDITAR_APUNTE_EXITO = 'EDITAR_APUNTE_EXITO'
export const EDITAR_APUNTE_ERROR = 'EDITAR_APUNTE_ERROR'
export const ELIMINAR_APUNTE_EXITO = 'ELIMINAR_APUNTE_EXITO'
export const ACTUALIZAR_ESTADO_APUNTE = 'ACTUALIZAR_ESTADO_APUNTE'

//Control capacitaciones
export const OBTENER_CAPACITACIONES_CONTROL = 'OBTENER_CAPACITACIONES_CONTROL'
export const OBTENER_CAPACITACIONES_CONTROL_EXITO = 'OBTENER_CAPACITACIONES_CONTROL_EXITO'
export const GUARDAR_CAPACITACION_ACTIVO = 'GUARDAR_CAPACITACION_ACTIVO'
export const OBTENER_INFO_CONTROL = 'OBTENER_INFO_CONTROL'
export const OBTENER_INFO_CONTROL_EXITO = 'OBTENER_INFO_CONTROL_EXITO'
export const AGREGAR_DOCENTE_CAPACITACION = 'AGREGAR_DOCENTE_CAPACITACION';
export const AGREGAR_DOCENTE_CAPACITACION_EXITO = 'AGREGAR_DOCENTE_CAPACITACION_EXITO';
export const CAMBIAR_ESTADO_DOCENTE = 'CAMBIAR_ESTADO_DOCENTE';
export const CAMBIAR_ESTADO_DOCENTE_EXITO = 'CAMBIAR_ESTADO_DOCENTE_EXITO';
export const CAMBIAR_ESTADO_DOCENTE_ERROR = 'CAMBIAR_ESTADO_DOCENTE_ERROR';
export const ELIMINAR_DOCENTE_CAPACITACION = 'ELIMINAR_DOCENTE_CAPACITACION';
export const ELIMINAR_DOCENTE_CAPACITACION_EXITO = 'ELIMINAR_DOCENTE_CAPACITACION_EXITO';
export const SESION_ACTIVA = 'SESION_ACTIVA';
export const GUARDAR_PONENTE_TENTATIVO = 'GUARDAR_PONENTE_TENTATIVO';
export const GUARDAR_PONENTE_TENTATIVO_EXITO = 'GUARDAR_PONENTE_TENTATIVO_EXITO';
export const GUARDAR_PONENTE_TENTATIVO_CONFIRMADO = 'GUARDAR_PONENTE_TENTATIVO_CONFIRMADO';
export const GUARDAR_PONENTE_TENTATIVO_CONFIRMADO_EXITO = 'GUARDAR_PONENTE_TENTATIVO_CONFIRMADO_EXITO';
export const NUEVA_IMAGEN = 'NUEVA_IMAGEN';
export const NUEVA_IMAGEN_EXITO = 'NUEVA_IMAGEN_EXITO';
export const NUEVA_IMAGEN_ERROR = 'NUEVA_IMAGEN_ERROR'
export const ELIMINAR_IMAGEN_CONFIRMACION = 'ELIMINAR_IMAGEN_CONFIRMACION';
export const ELIMINAR_IMAGEN_CONFIRMACION_EXITO = 'ELIMINAR_IMAGEN_CONFIRMACION_EXITO';
export const ELIMINAR_IMAGEN_CONFIRMACION_ERROR = 'ELIMINAR_IMAGEN_CONFIRMACION_ERROR'
export const NUEVA_EVIDENCIA_EXITO = 'NUEVA_EVIDENCIA_EXITO';
export const NUEVA_EVIDENCIA_ERROR = 'NUEVA_EVIDENCIA_ERROR'
export const ELIMINAR_EVIDENCIA_CONFIRMACION = 'ELIMINAR_EVIDENCIA_CONFIRMACION';
export const ELIMINAR_EVIDENCIA_CONFIRMACION_EXITO = 'ELIMINAR_EVIDENCIA_CONFIRMACION_EXITO';
export const ELIMINAR_EVIDENCIA_CONFIRMACION_ERROR = 'ELIMINAR_EVIDENCIA_CONFIRMACION_ERROR'
export const CHANGE_VALUES = 'CHANGE_VALUES';
export const GUARDAR_DATOS_FORM = 'GUARDAR_DATOS_FORM';
export const GUARDAR_DATOS_FORM_ERROR = 'GUARDAR_DATOS_FORM_ERROR';
export const DOCENTE_ELIMINADO_TENTATIVO_CONFIRMADO_EXITO = 'DOCENTE_ELIMINADO_TENTATIVO_CONFIRMADO_EXITO'
export const ACTUALIZAR_ESTADO_INDICADOR_CONTROL = 'ACTUALIZAR_ESTADO_INDICADOR_CONTROL';
export const ACTUALIZAR_ESTADO_AJUSTE_CONTROL = 'ACTUALIZAR_ESTADO_ESTADO_CONTROL';
//Modal
export const OPEN_MODAL_BASIC = 'OPEN_MODAL_BASIC';
export const CLOSE_MODAL_BASIC = 'CLOSE_MODAL_BASIC';
export const OPEN_MODAL_LARGE = 'OPEN_MODAL_LARGE';
export const CLOSE_MODAL_LARGE = 'CLOSE_MODAL_LARGE';
export const ACTUALIZAR_CHILDREN_MODAL_LARGE = 'ACTUALIZAR_CHILDREN_MODAL_LARGE'
export const CLOSE_MODAL_CUSTOM = 'CLOSE_MODAL_CUSTOM'
export const OPEN_MODAL_CUSTOM = 'OPEN_MODAL_CUSTOM'
//Ligthbox
export const OPEN_LIGHTBOX = 'OPEN_LIGHTBOX';
export const CLOSE_LIGHTBOX = 'CLOSE_LIGHTBOX';
export const SET_SOURCE_LIGHTBOX = 'SET_SOURCE_LIGHTBOX';
export const NEXT_SLIDE = 'NEXT_SLIDE';
export const PREV_SLIDE = 'PREV_SLIDE';

export const programacionesTypes = {
  OBTENER_PROGRAMACIONES: 'OBTENER_PROGRAMACIONES',
  OBTENER_PROGRAMACIONES_EXITO: 'OBTENER_PROGRAMACIONES_EXITO',
  OBTENER_PROGRAMACIONES_ERROR: 'OBTENER_PROGRAMACIONES_ERROR',
  NUEVO_PROGRAMACION: 'NUEVO_PROGRAMACION',
  PROGRAMACION_ACTIVA: 'PROGRAMACION_ACTIVA',
  EDITAR_PROGRAMACION: 'EDITAR_PROGRAMACION',
  ELIMINAR_PROGRAMACION: 'ELIMINAR_PROGRAMACION',
  OBTENER_CICLOS_PROGRAMACION: 'OBTENER_CICLOS_PROGRAMACION',
  OBTENER_CICLOS_PROGRAMACION_EXITO: 'OBTENER_CICLOS_PROGRAMACION_EXITO',
  OBTENER_CICLOS_PROGRAMACION_ERROR: 'OBTENER_CICLOS_PROGRAMACION_ERROR',
  NUEVO_PROYECTO_PROGRAMACION_EXITO: 'NUEVO_PROYECTO_PROGRAMACION_EXITO',
  NUEVO_PROYECTO_PROGRAMACION_ERROR: 'NUEVO_PROYECTO_PROGRAMACION_ERROR',
  ELIMINAR_PROYECTO_PROGRAMACION_EXITO: 'ELIMINAR_PROYECTO_PROGRAMACION_EXITO',
  PROYECTO_PROGRAMACION_ACTIVO: 'PROYECTO_PROGRAMACION_ACTIVO',
  EDITAR_PROYECTO_PROGRAMACION_EXITO: 'EDITAR_PROYECTO_PROGRAMACION_EXITO',
  NUEVO_CONTRATO_EXITO: 'NUEVO_CONTRATO_EXITO', 
  NUEVO_CONTRATO_ERROR: 'NUEVO_CONTRATO_ERROR', 
  CONTRATO_ACTIVO: 'CONTRATO_ACTIVO',
  ELIMINAR_CONTRATO_EXITO: 'ELIMINAR_CONTRATO_EXITO',
  ACTUALIZANDO_PROCENTAJE_SUBIDA_ARCHIVO: 'ACTUALIZANDO_PROCENTAJE_SUBIDA_ARCHIVO' 
}